<div class="add-sale-container">
  <form [formGroup]="saleInputFormGroup" class="add-sale-form" *ngIf="sale.status === 'in-progress'&& !showConfirmation && !showStartedInfo" >
    <div class="fields-container" *ngFor="let field of fields">
      <p class="field-label">{{ field.label }}</p>
      <div class="input-field"
        [class.error]="saleInputFormGroup.get(field.id).hasError('required') || saleInputFormGroup.get(field.id).hasError('minlength') || saleInputFormGroup.get(field.id).hasError('pattern')"
      >
        <input *ngIf="field.type === 'text'" type="text" [value]="field.value" [formControlName]="field.id"/>
        <app-custom-select
          *ngIf="field.type === 'select'"
          [options]="field.options"
          [formControlName]="field.id"
        ></app-custom-select>
        <div
          *ngIf="field.type === 'time'"
        >
          <input
            [ngxTimepicker]="picker"
            [formControlName]="field.id"
            [disableClick]="true"
            readonly
          >
          <ngx-material-timepicker-toggle
            [for]="picker"
            class="time-toggle-icon"
          ></ngx-material-timepicker-toggle>
          <ngx-material-timepicker
            #picker
            [ngxMaterialTimepickerTheme]="pickerTheme"
            defaultTime="9:00 AM"
          ></ngx-material-timepicker>
        </div>
        <div *ngIf="field.type === 'date'" class="date-picker">
          <input [matDatepicker]="datePicker" [value]="field.value" [formControlName]="field.id">
          <div class="icon" (click)="datePicker.open()">
            <img [class.has-value]="saleDate.toString().length > 0" src="assets/img/icons/add.white.svg" />
          </div>
          <mat-datepicker [calendarHeaderComponent]="calendarHeader" #datePicker></mat-datepicker>
        </div>
       
      </div>
    </div>
    <div class="button">
      <button class="create-sale" [disabled]="!saleInputFormGroup.valid" (click)="submitSale()">Create Sale</button>
    </div>
  </form>
  <div class="submitting" *ngIf="sale.status === 'submitting' && !showConfirmation && !showStartedInfo">
    <div class="content">
      <h3>Contacting {{ source }}</h3>
      <mat-progress-bar mode="indeterminate" *ngIf="sale.status === 'submitting'"></mat-progress-bar>
    </div> 
  </div>
  <div class="error" *ngIf="sale.status === 'error' && !showConfirmation && !showStartedInfo">
    <div class="content">
      <h3> Error </h3>
      <p>{{ sale.statusMessage }}</p>
      <button class="try-again" (click)="tryAgain()">Change Details</button>
      <button class="create-sale" (click)="submitSale()">Submit Again</button>
    </div>
  </div>

  <div class="error" *ngIf="showConfirmation">
    <div class="content">
      <h3> Warning </h3>
      <p>This is an existing sale. Do you want to delete it and create a new version?</p>
      <button class="try-again" (click)="overwriteSale()">Yes</button>
      <button class="create-sale" (click)="cancelOverwrite()">No</button>
    </div>
  </div>

  <div class="error" *ngIf="showStartedInfo">
    <div class="content">
      <h3> Warning </h3>
      <p>This is an existing sale which has been started – it cannot be downloaded again.</p>
      <button class="create-sale" (click)="close()">OK</button>
      
    </div>
  </div>

  
    <form class="add-sale-form" *ngIf="organisations !== undefined && organisations.status === 'in-progress' && sale.status !== 'in-progress'">
      <div class="fields-container">
        <p class="field-label">ID</p>
        
        <mat-form-field  class="example-full-width" style="width:170px" >
          <input type="text"
                 placeholder="Select or create one"
                 aria-label="Number"
                 matInput
                 [formControl]="myControl"
                 [matAutocomplete]="auto"
                 (keyup)="onInputChange($event)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelect($event)">
            <mat-option *ngFor="let option of buyers$ | async" [value]="option.id">
              {{option.id}} ({{option.type==="E"?"Buyer":(option.type==="A"?"Broker":"Both")}})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
       
      </div>
      <div class="fields-container">
        
        <p class="field-label">Type</p>
          
            <mat-form-field style="width:70px">
              <mat-select [(value)]="selected">
                
                <mat-option value="Buyer">Buyer</mat-option>
                <mat-option value="Broker">Broker</mat-option>
                <mat-option value="Both">Both</mat-option>
              </mat-select>
            </mat-form-field>
           <!--   <p>You selected: {{selected}}</p>-->
        </div>
      <div class="fields-container">
  
        <p class="field-label">Code</p>
        <div class="input-field code-field">
        <input type="text" name="orgcode"  [disabled]="selected==='Broker'" [(ngModel)]="this.selectOrg.code "/>
        </div>
      </div>
  
      <div class="fields-container">
  
        <p class="field-label">Name</p>
        <div [ngClass]="this.selectOrg.name===''?'input-field error name-field':'input-field name-field'" >
        <input type="text" name="orgname"  [(ngModel)]="this.selectOrg.name "/>
        </div>
      </div>
  
      <!--
      <div class="fields-container">
  
        <p class="field-label">Org. Type</p>
        <div class="input-field">
        <input type="text" [value]="this.selectOrg != null? (this.selectOrg.type === 'A'? 'Broker' : (this.selectOrg.type === 'B'?'Both':'Buyer')) : 'Buyer'"/>
        
        </div>
      </div>-->
     
      <div class="fields-container">
  
        <p class="field-label">FG Colour</p>
        <div class="input-field color-field">
        <input type="text" name="fgcolor"  [disabled]="selected==='Buyer'" [(ngModel)]=" this.selectOrg.foregroundColor  "/>
        </div>
      </div>
      <div class="fields-container" >
  
        <p class="field-label" >BG Colour</p>
        <div class="input-field color-field">
        <input type="text" name="bkcolor" [disabled]="selected==='Buyer'" [(ngModel)]=" this.selectOrg.backgroundColor "/>
        </div>
      </div>
      
      <div class="fields-container">
        
        <p class="field-label">Auto Transfer</p>
          
            <mat-form-field style="width:100px">
              <mat-select [(value)]="selectedAutoTransfer">
                <mat-option value="EMPTY"></mat-option>
                <ng-container *ngFor="let option of organisations.brokersAndBuyers">
                  <ng-container *ngIf="option.type !== 'A'">
                    <mat-option  [value]="option.id">
                      {{option.id}}
                    
                  </mat-option>
                  </ng-container>
                </ng-container>
                <ng-container *ngFor="let option of organisations.brokers">
                  <ng-container *ngIf="option.type !== 'A'">
                    <mat-option  [value]="option.id">
                      {{option.id}}
                    
                  </mat-option>
                  </ng-container>
                </ng-container>

              </mat-select>
            </mat-form-field>
           <!--   <p>You selected: {{selected}}</p>-->
        </div>
      <div class="fields-container">
  
        <p class="field-label">Group</p>
        <div class="input-field code-field">
        <input type="text" name="group"  [disabled]="selected==='Buyer'" [(ngModel)]="this.selectOrg.group"/>
        </div>
      </div>
      <div class="button">
        <button class="create-sale" [disabled]="this.selectOrg.name==='' || selectId ===''" (click)="createUpdateOrg()">Save</button>
      </div>
    </form>
  
</div>
